form {
    margin: 5rem auto 0;
    width: 24rem;
  
    div {
      width: inherit;
      margin-bottom: 1rem;
    }
  
    label {
      display: block;
      color: #000000;
    }
  
    input {
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      width: inherit;
      height: 2rem;
      border: solid black;
      border-radius: 4px;
      box-sizing: border-box;
      font-family: "Urbanist";
    }
  
    input[type="submit"] {
      height: 2.5rem;
      font-size: 1rem;
      color: #fff;
      background: rgb(155, 155, 155);
      cursor: pointer;
      border: none;
    }
  
    p {
      margin: 0;
      color: #ff0048;
    }
    .image-container {
      display: flex;
      flex-wrap: wrap; /* To wrap images to the next line if there's no space */
    }
    
    .small-image {
      width: 50px; /* Adjust the width as needed */
      height: 50px; /* Adjust the height as needed */
      object-fit: cover; /* To prevent image distortion */
      margin: 5px; /* Add spacing between images */
    }
  }
 