/* ===================================================Transparent Btn Css ===================================*/
button.transparent-btn {
    margin: 10px 0px 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 900;
    font-family: "Urbanist";
    border: 2px solid white;
    background-color: transparent;
    color: rgb(0, 0, 0);
}
button.transparent-btn a {
    color: rgb(0, 0, 0);
}
/* ====================================================== Color Btn Css================================= */
button.color-btn {
    margin: 10px 0px 0px;
    padding: 10px;
    font-size: 16px;
    font-weight: 900;
    border: 2px solid rgb(0, 0, 0);
    background-color: transparent; 
    color: rgb(0, 0, 0);
    font-family: "Urbanist";
}
button.color-btn:hover {
    border: 2px solid rgb(0, 0, 0);
    background-color: transparent; 
    color: rgb(0, 0, 0);  
    background-color: rgb(189, 189, 189);
}
button.color-btn:hover a{
     
    color: rgb(0, 0, 0);  
}
button.color-btn a{
        color: rgb(0, 0, 0);
        text-decoration: none; 
        font-family: "Urbanist";
}
button.color-btn a:hover{
    color: rgb(0, 0, 0);
    text-decoration: none; 
}