.NavbarItems {
    background:#fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: "Urbanist";
    margin: 1%;
}

.navbar-logo {
    color: #A5685E;
    margin-top: 1%;
    margin-left: 1%;
}
.memo-logo  {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 1%;
    display:inline-block;
    vertical-align:middle;
    
}

  .memo-logo  img{
    width:100%;
  }

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    
}

.nav-links {
    color: #A5685E;
    text-decoration: none;
    padding: 15px 20px;
}

.nav-links:hover {
    background-color: rgba(215, 213, 213, 0.977);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #A5685E;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}
/* .styles-module_whatsappChatBox__MdQ5U {
   overflow-y: visible  !important;
} */
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
}
.my-float{
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1160px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 10000;
       
    }

    .nav-menu.active {
        background: black;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1000;
    }

    .nav-links {
        text-align: center;
        padding: 15px 20px;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: rgba(215, 213, 213, 0.977);
        border-radius: 0;
    }

    /* .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    } */

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        background-color: bisque;
    }

    .fa-times {
        color: #A5685E;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: white;
        text-decoration: none;
        color: #A5685E;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: rgba(215, 213, 213, 0.977);
        color: #A5685E;
        transition: 250ms;
    }

   
}
@media only screen and (min-width: 220px) and (max-width: 569px){
  
    .memo-logo {
        width: 70px;
        height: 70px;
        
    }
}