*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: "Urbanist", sans-serif;
}


h1.team-title {
    font-family: "Urbanist", sans-serif;
    font-size: 40px;
}

/*======================================================= Home Page Css============================================  */
/* After Slider Section */
section.hero-text-section {
    padding: 80px 100px;
    background: linear-gradient(90deg, rgb(58, 42, 209) 0%, rgba(73,63,252,1) 100%);
    color: white;
    display: block;
}

/* PagesMainSectionContent Like About section title & paragraph  , services section title & paragraph etc */

/* Service Section On Home Page */
section.services {
    margin-top: 90px;
}
/* About Section On Home Page */
section.about {
    margin: 50px 10px;
}

/* Team Section On Home Page */
.row-team{
    display: flex;
    flex-wrap: nowrap;
}
.team-column{
    flex-basis: 50%;
    margin: 0px 0px;
}
.team-col{
    padding: 10px 0px;
    /* background: rgba(215, 213, 213, 0.977); */
    color: black;
    font-family: "Urbanist";
    
    margin-left: 10%;
}
.team-section-container{
    width: 100%;
    
    
    
}  
img.team-img {
    width: 60%;
    
    
}
.home-page-team-content {
    padding: 100px 100px;
}
.home-page-blog{
margin-top:50px;
}
.homepage-work-with-us-section{
    margin-top:100px;
}
p.team-para {
    margin-top: 5%;
    font-family: "Urbanist", sans-serif;
}
/* Media Query */
@media only screen and (min-width: 570px) and (max-width: 969px){
    /*Mobile [250px -> 768px]*/
    section.hero-text-section {
        padding: 25px 25px;
        background: linear-gradient(90deg, rgb(58, 42, 209) 0%, rgba(73,63,252,1) 100%);
        color: white;
        display: block;
    }
    .row-team {
        flex-direction: column-reverse;
    }
    .team-col {
        margin-top: -20px;
    }
}
@media only screen and (min-width: 220px) and (max-width: 569px){
    /*Mobile [250px -> 768px]*/
    section.hero-text-section {
        padding: 25px 25px;
        font-size: small;
    }
    .row-team {
        flex-direction: column-reverse;
    }
    .team-col {
        margin-top: -20px;
    }
    .home-page-team-content {
        padding: 50px 40px;
    }
    h1.team-title {
        font-size: 30px;
        font-family: "Urbanist", sans-serif;
        
    }
    p.team-para {
        font-size: small;
    }
    img.team-img {
        width: 80%;
        margin-left: 10%;
    }
    section.services {
        margin-top: 20px;
    }
}