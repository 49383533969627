* {
    box-sizing: border-box;
    margin: 0;
  }
  
  :root {
    --heights: 80vh;
    --widths: 100%;
  }
  
  .slider-container {
    height: var(--heights);
    width: var(--widths);
    position: relative;
    margin: auto;
    overflow: hidden;
    margin-top: 2%;
  }
  
  .active {
    display: inline-block;
  }
  
  .inactive {
    display: none;
  }
  
  .slides {
    height: var(--heights);
    width: var(--widths);
    position: relative;
  }
  
  .slide-image {
    width: 100%;
    height: auto;
    position: absolute;
    
  }
  
  .slide-title,
  .slide-text {
    width: 100%;
    height: 100%;
    color: black;
    font-size: 50px;
    position: absolute;
    text-align: left;
    margin-left: 90px;
    top: 40%;
    z-index: 10;
    font-family: "Urbanist";
  }
  
  .slide-text {
    top: 50%;
    font-size: 2rem;
    color: black;
    font-family: "Urbanist";
}
  
  .prev,
  .next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
  }
  
  
  .next {
    right: 0;
    border-radius: 5px 0px 0px 5px;
  }
  
  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 200;
  }
  
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
  }
  
  .active-dot,
  .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

@media only screen and (min-width: 220px) and (max-width: 659px){
    /*Mobile [250px -> 768px]*/
    :root {
      --heights: 50vh;
    }
    .slider-container {
      height: var(--heights);
    }
    .slide-image {
      object-fit: fill;
  }
    .slide-title {
      font-size: 30px;
      margin-left: 50px;
  }
  .slide-text {
    font-size: 15px;
    margin-left: 50px;
}
}