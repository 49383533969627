.about-intro{
    display: block;
    width: 100%;
}
.wrapper{
    margin: 1% auto;
    width: 80%;
    padding: 10px;

}
.wrapper h2{
    text-transform: uppercase;
    font-family: "Urbanist";
    font-weight: bold;
    text-align: center;
    font-size: 60px;
    color: #fff;
    margin:  0;
}
.single-service {
position: relative;
width: 31%;
height: 320px;
background: #fff;
box-sizing: border-box;
padding: 0 15px;
transition: .5s;
overflow: hidden;
font-family: "Urbanist";
float: left;

margin: 0 10px;
text-align: center;
}
.line {
width: 150px;
height: 3px;
background: #fff;
margin: 0 auto 60px auto;
}
.single-service p{            
    color: black;
    font-size: 14px;
    font-family: "Urbanist";
}
.single-service h3 {
    font-size: 25px;
    text-transform: uppercase;
    font-family: poppins;
    letter-spacing: 1px;
    color: black;
    font-family: "Urbanist";
}
.social {
width: 60px;
height: 60px;
background: #A5685E;
border-radius: 50%;
margin: 5% auto;
}
.social i {
font-size: 30px;
padding: 15px;
    color: #fff;
}
.single-service {
    height: 360px;
    margin: 20px 10px 80px 10px;
    box-shadow: -7px 13px 12px rgb(155, 155, 155);
}
.single-service:hover {
    box-shadow: -7px 13px 12px rgb(146 141 191);
}

.single-service span {
position: absolute;
top: 0;
left: -110%;
width: 100%;
height: 100%;
background: rgba(215, 213, 213, 0.977);
transition: .7s;
transform: skewX(10deg);
}
.single-service:hover span{
    left: 110%;
}

@media (max-width:1090px){
    .wrapper {
width: 100%;
}
.single-service {
width: 100%;
margin-bottom: 30px;
}
.wrapper h2 {
font-size: 30px;
}
}
.our-service-h1{
  text-align: center;
  font-size: 35px;
  font-weight: 700;
}
.our-service-p{
    text-align: center;
  }
.right-float-of-service-page{
     visibility: hidden;
}